<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="90px">
      <el-form-item label="LORA名" prop="lora_name">
        <el-input v-model="queryParams.lora_name" placeholder="LORA名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="启用状态" prop="enable">
        <el-select v-model="queryParams.enable" style="width: 150px" placeholder="请选择启用状态" clearable>
          <el-option label="启用" :value="1"></el-option>
          <el-option label="停用" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchLoraList">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="handleAddLora">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          <span>新增</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="loraList" style="width: 100%">
      <el-table-column prop="lora_name" label="Lora 名称" width="250"/>
      <el-table-column prop="trigger_word" label="触发词" width="250"/>
      <el-table-column prop="model_type" label="模型类型" width="100"/>
      <el-table-column prop="enable" label="启用状态" width="100">
        <template #default="scope">
          <el-tag :type="scope.row.enable === 1 ? 'success' : 'danger'">
            {{ scope.row.enable === 1 ? '启用' : '禁用' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="ComfyUI工具数" width="100">
        <template #default="scope">
          <el-tag type="info">
            {{ scope.row.tool_codes.length || 0 }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="200">
        <template #default="scope">
          <el-input v-model="scope.row.remark" @change="editRemark(scope.row)"
                    placeholder="输入备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间"/>
      <el-table-column prop="update_time" label="更新时间"/>
      <el-table-column label="操作" width="200">
        <template #default="scope">
          <el-button @click="handleEdit(scope.row)" size="small">编辑</el-button>
          <el-button
              type="danger"
              @click="handleDelete(scope.row.id)"
              size="small"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.page_num"
                v-model:limit="queryParams.page_size" @pagination="fetchLoraList"/>

    <!-- 编辑Lora模态框 -->
    <el-dialog
        title="编辑 Lora"
        v-model="editLoraVisible"
        width="50%"
        @close="resetForm"
    >
      <el-form :model="loraForm" ref="loraFormRef" label-width="100px">
        <el-form-item label="Lora 名称" prop="lora_name" required>
          <el-input v-model="loraForm.lora_name"/>
        </el-form-item>
        <el-form-item label="触发词" prop="trigger_word">
          <el-input v-model="loraForm.trigger_word"/>
        </el-form-item>
        <el-form-item label="模型类型" prop="model_type">
          <el-select v-model="loraForm.model_type" placeholder="请选择模型类型" clearable>
            <el-option label="F1" value="F1"></el-option>
            <el-option label="SD1.x" value="SD1.x"></el-option>
            <el-option label="SD2.x" value="SD2.x"></el-option>
            <el-option label="SDXL" value="SDXL"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用状态" prop="enable">
          <el-switch v-model="loraForm.enable" :active-value="1" :inactive-value="0" active-text="启用"
                     inactive-text="禁用"/>
        </el-form-item>
        <el-form-item label="comfyui工具" prop="tool_codes">
          <el-select v-model="loraForm.tool_codes" multiple placeholder="选择comfyui工具">
            <el-option
                v-for="tool in allTools"
                :key="tool.id"
                :label="tool.name"
                :value="tool.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="loraForm.remark"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="editLoraVisible = false">取消</el-button>
        <el-button type="primary" :loading="editLoading" @click="handleSaveLora">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import {ElMessage, ElMessageBox} from 'element-plus';
import {Search, Plus, CirclePlusFilled} from "@element-plus/icons-vue";
import axiosInstance from '@/api/axiosInstance';
import Pagination from "@/components/pagination/index.vue";

export default {
  components: {CirclePlusFilled, Pagination, Search, Plus},
  data() {
    return {
      loraName: '',
      total: 0,
      loraList: [],
      allTools: [],
      selectedTools: [],
      editLoraVisible: false,
      queryParams: {
        page_num: 1,
        page_size: 10,
        lora_name: '',
        enable: null,
      },
      editLoading: false,
      loraForm: {
        id: null,
        lora_name: '',
        trigger_word: '',
        model_type: '',
        enable: 1,
        remark: '',
        tool_codes: []  // 添加 tool_codes 字段来保存关联的工具
      }
    };
  },
  created() {
    this.fetchLoraList();
    this.fetchAllTools();
  },
  methods: {
    fetchLoraList() {
      axiosInstance.post('/api/comfyui/lora/page_query', {
        ...this.queryParams
      }).then((response) => {
        const {total, results} = response.data.data;
        this.total = total;
        this.loraList = results;
      }).catch((error) => {
        ElMessage.error('获取Lora列表失败');
        console.error(error);
      });
    },
    fetchAllTools() {
      axiosInstance.post('/api/comfyui/tool/page_query', {
        page_num: 1,
        page_size: 1000,
      }).then((response) => {
        if (response.data && response.data.code === 0) {
          this.allTools = response.data.data.results;
        }
      }).catch((error) => {
        ElMessage.error('获取工具列表失败');
        console.error(error);
      });
    },
    handleAddLora() {
      this.resetForm();
      this.editLoraVisible = true;
    },
    handleEdit(row) {
      this.loraForm = {...row};  // 复制行数据到表单中
      this.editLoraVisible = true;
    },
    handleDelete(id) {
      ElMessageBox.confirm('确定删除此Lora记录吗？', '删除 Lora', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosInstance.post('/api/comfyui/lora/delete', {id})
            .then(res => {
              if (res.data.code === 0) {
                ElMessage.success('删除成功');
                this.fetchLoraList();
              } else {
                ElMessage.error('删除失败');
              }
            }).catch(() => {
          ElMessage.error('删除失败');
        });
      })
    },
    handleSaveLora() {
      if (!this.loraForm.lora_name) {
        ElMessage.error('Lora名称不能为空');
        return;
      }
      this.editLoading = true;
      axiosInstance.post('/api/comfyui/lora/update', this.loraForm).then(res => {
        this.editLoading = false;
        if (res.data.code === 0) {
          ElMessage.success('保存成功');
          this.fetchLoraList();
          this.editLoraVisible = false;
          this.fetchLoraList();
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.editLoading = false;
        ElMessage.error('保存失败');
      });
    },
    resetForm() {
      this.loraForm = {
        id: null,
        lora_name: '',
        trigger_word: '',
        model_type: '',
        enable: 1,
        remark: '',
        tool_codes: [] // 清空工具关联字段
      };
    },
    // 编辑备注
    editRemark(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        remark: row.remark
      };
      axiosInstance.post('/api/comfyui/lora/edit_remark', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('备注更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('备注更新失败');
          });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>

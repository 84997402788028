<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="90px">
      <el-form-item label="用户名" prop="model_code">
        <el-input v-model="queryParams.username" placeholder="用户名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="模型码" prop="model_code">
        <el-input v-model="queryParams.model_code" placeholder="模型码，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="模型名" prop="model_name">
        <el-input v-model="queryParams.model_name" placeholder="模型名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="queryParams.remark" placeholder="备注，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getTableData">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="handleClickAdd">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          <span>发起对话</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="tableData" v-loading="tableLoading" height="calc(100vh - 250px)" style="width: 100%;">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="username" label="用户名" width="100"></el-table-column>
      <el-table-column prop="model_code" label="模型码" width="100"></el-table-column>
      <el-table-column prop="model_name" label="模型名" width="100"></el-table-column>
      <el-table-column prop="role_name" label="模型角色" width="150"></el-table-column>
      <el-table-column prop="input_content" label="对话输入" width="250"></el-table-column>
      <el-table-column prop="output_content" label="对话输出" width="250"></el-table-column>
      <el-table-column prop="create_time" label="创建时间"/>
      <el-table-column prop="update_time" label="更新时间"/>
      <el-table-column fixed="right" label="操作" width="200">
        <template #default="scope">
          <div style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap">
            <el-button style="margin-bottom: 4px;margin-left: 12px" type="primary" size="small"
                       :loading="restartChatLoading"
                       @click.prevent="handleRestartChat(scope.row)">重新对话
            </el-button>
            <el-button style="margin-bottom: 4px;" type="danger" size="small"
                       @click.prevent="handleClickDelete(scope.row)">删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.page_num"
                v-model:limit="queryParams.page_size" @pagination="getTableData"/>
    <!--发起对话区域-->
    <div>
      <el-dialog title="发起对话"
                 v-model="dialogVisible"
                 width="60%"
                 :close-on-click-modal="false">
        <el-form
            :model="recordForm"
            label-position="left"
            label-width="auto">
          <el-form-item label="大模型" prop="model_code" required>
            <el-select v-model="recordForm.model_code" placeholder="请选择大模型">
              <el-option
                  v-for="config in apiConfigList"
                  :key="config.id"
                  :label="config.model_name"
                  :value="config.model_code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="模型角色" prop="role_code" required>
            <el-select v-model="recordForm.role_code" placeholder="请选择模型角色">
              <el-option
                  v-for="config in roleConfigList"
                  :key="config.id"
                  :label="config.role_name"
                  :value="config.role_code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="对话输入：" prop="input_content" required>
            <el-input v-model="recordForm.input_content" type="textarea"
                      :autosize="{ minRows: 4, maxRows: 6}"
                      placeholder="请输入对话内容，例如：优化并扩写提示词'一名年轻中国女孩，在上海街头，背景是东方明珠'">
            </el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="startModelChat" :loading="submitLoading">提交</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Check, ArrowDown, Upload} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";

export default {
  name: "luma-account",
  components: {
    Pagination,
    CirclePlusFilled, Search, Download, Check, ArrowDown, Upload
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },

  data() {
    return {
      userInfoList: [],
      selectedIds: [],
      apiConfigList: [],
      roleConfigList: [],
      recordForm: {
        id: null,
        model_code: '',
        model_name: '',
        role_code: '',
        role_name: '',
        input_content: '',
        output_content: '',
      },
      queryParams: {
        page_size: 10,
        page_num: 1,
        username: null,
        model_code: null,
        model_name: null,
      },
      tableLoading: false,
      tableData: [],
      total: 0,
      dialogVisible: false,
      isUpdate: false,
      submitLoading: false,
      restartChatLoading: false,
    };
  },
  created() {
    if (this.$route.query.taskId) {
      this.queryParams.taskId = this.$route.query.taskId;
    }
    if (this.$route.query.uid) {
      this.queryParams.userId = this.$route.query.uid;
    }
  },
  mounted() {
    if (this.isSuperAdmin) {
      this.queryUserInfo(null);
    }
    this.getTableData();
    this.getModelApiConfigInfo();
    this.getModelRoleConfigInfo();
  },
  methods: {
    resetForm() {
      this.recordForm = {
        id: null,
        model_code: '',
        model_name: '',
        role_code: '',
        input_content: '',
        output_content: '',
      }
    },
    queryUserInfo(username) {
      console.log("queryUserInfo: ", username)
      axiosInstance.post('/api/user/page_query', {
        "username": username || "",
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.userInfoList = response.data.data.results;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error);
      });
    },
    getModelApiConfigInfo() {
      axiosInstance.post('/api/big_model/api_config/page_query', {
        page_size: 100,
        page_num: 1,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.apiConfigList = data.results;
        }
      }).catch(error => {
        console.error(error)
      });
    },
    getModelRoleConfigInfo() {
      axiosInstance.post('/api/big_model/role_config/page_query', {
        page_size: 100,
        page_num: 1,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.roleConfigList = data.results;
        }
      }).catch(error => {
        console.error(error)
      });
    },
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      this.selectedIds = rows.map(item => item.id) || [];
    },
    getTableData() {
      this.tableLoading = true;
      axiosInstance.post('/api/big_model/chat_record/page_query', {
        ...this.queryParams
      }).then(response => {
        this.tableLoading = false;
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.total = data.total;
          this.tableData = data.results;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.tableLoading = false;
        console.error(error)
      });
    },
    handleClickAdd() {
      this.resetForm();
      this.isUpdate = false;
      this.dialogVisible = true;
    },
    // 查看参数
    handleClickEdit(row) {
      this.isUpdate = true;
      this.submitLoading = false;
      this.recordForm = row;
      this.dialogVisible = true;
    },
    handleRestartChat(row) {
      this.restartChatLoading = true;
      axiosInstance.post('/api/big_model/chat/start', {
        "model_code": row.model_code,
        "role_code": row.role_code,
        "input_content": row.input_content,
      }).then(response => {
        this.restartChatLoading = false;
        if (response.data && response.data.code === 0) {
          this.$message.success('操作成功');
          this.dialogVisible = false;
          this.getTableData();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.restartChatLoading = false;
        console.error(error)
        this.$message.error(error.message);
      });
    },
    startModelChat() {
      if (!this.recordForm.model_code) {
        this.$message.error('请选择模型');
        return;
      }
      if (!this.recordForm.role_code) {
        this.$message.error('请选择模型角色');
        return;
      }
      this.submitLoading = true;
      axiosInstance.post('/api/big_model/chat/start', {
        ...this.recordForm
      }).then(response => {
        this.submitLoading = false;
        if (response.data && response.data.code === 0) {
          this.$message.success('操作成功');
          this.dialogVisible = false;
          this.getTableData();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.submitLoading = false;
        console.error(error)
        this.$message.error(error.message);
      });
    },


    // 编辑任务参数
    saveEditInfo() {
      if (!this.recordForm.model_code) {
        this.$message.error('请输入模型码');
        return;
      }
      if (!this.recordForm.model_name) {
        this.$message.error('请输入模型名');
        return;
      }
      this.submitLoading = true;
      axiosInstance.post('/api/big_model/chat_record/update', {
        ...this.recordForm
      }).then(response => {
        this.submitLoading = false;
        if (response.data && response.data.code === 0) {
          this.$message.success('操作成功');
          this.dialogVisible = false;
          this.getTableData();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.submitLoading = false;
        console.error(error)
        this.$message.error(error.message);
      });
    },
    handleClickDelete(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosInstance.post('/api/big_model/chat_record/delete', {
          id: row.id
        }).then(response => {
          if (response.data && response.data.code === 0) {
            this.$message.success('删除成功');
            this.getTableData();
          } else {
            this.$message.error(response.data.msg);
          }
        }).catch(error => {
          console.error(error)
          this.$message.error('删除失败');
        });
      })
    },
  },
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.try-on-result-image {
  flex-shrink: 0;
  cursor: pointer;
  border: 1px solid transparent; /* 默认透明的边框 //border-radius: 20px; */
  width: 270px;
  height: 360px;
  margin: 10px;
  z-index: 1;
}
</style>
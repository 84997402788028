<template>
  <el-container style="height: 100vh;">
    <el-aside :width="isCollapsed ? '0px' : '200px'">
      <el-menu
          active-text-color="#ffd04b"
          background-color="#545c64"
          class="menu-demo"
          :default-active="activeIndex"
          text-color="#fff"
          @open="handleOpen"
          @close="handleClose"
          :collapse="false"
          :router="true">
        <el-sub-menu v-if="isSuperAdmin || dyAccountManagerShow || dyAccountWorkShow" index="douyin-manage">
          <template #title>
            <douyin-icon class="el-icon"></douyin-icon>
            <span>抖音管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || dyAccountManagerShow" index="/douyin/account">
              <el-icon>
                <User/>
              </el-icon>
              <span>账号管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || dyAccountWorkShow" index="/douyin/work">
              <el-icon>
                <Promotion/>
              </el-icon>
              <span>作品管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || dyAccountWorkDataShow" index="/douyin/work-data">
              <el-icon>
                <Histogram/>
              </el-icon>
              <span>作品数据</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu v-if="isSuperAdmin || lumaAccountShow || lumaWorkShow" index="luma-manage">
          <template #title>
            <luma-icon class="el-icon"></luma-icon>
            <span>自动生成</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || lumaAccountShow" index="/video-create/account">
              <el-icon>
                <User/>
              </el-icon>
              <span>账号管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || lumaWorkShow" index="/video-create/work">
              <el-icon>
                <Promotion/>
              </el-icon>
              <span>作品管理</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu v-if="isSuperAdmin || contentMaterialShow || contentProductShow" index="content-manage">
          <template #title>
            <el-icon>
              <Promotion/>
            </el-icon>
            <span>内容管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || contentMaterialShow" index="/content/material">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>素材管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || contentMaterialShow" index="/content/tag">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>标签管理</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu index="ai-try-one" v-if="isSuperAdmin || aiTryOnDemoShow">
          <template #title>
            <cloth-icon class="el-icon"></cloth-icon>
            <span>AI换装</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/ai-try-on/user">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>用户信息</span>
            </el-menu-item>
            <el-menu-item index="/ai-try-on/demo">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>AI换装Demo</span>
            </el-menu-item>
            <el-menu-item index="/ai-try-on/task">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>v1换装记录</span>
            </el-menu-item>
            <el-menu-item index="/ai-try-on/v2">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>v2换装记录</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu v-if="isSuperAdmin || videoMixMenuShow || videoMixSceneShow" index="video-mix">
          <template #title>
            <cloth-icon class="el-icon"></cloth-icon>
            <span>视频混剪</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || aiTryOnDemoShow" index="/video-mix/scene-list">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>场景管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || aiTryOnDemoShow" index="/video-mix/task-list">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>混剪任务</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu v-if="isSuperAdmin || comfyUISceneShow" index="comfyui">
          <template #title>
            <cloth-icon class="el-icon"></cloth-icon>
            <span>ComfyUI</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || comfyUISceneShow" index="/comfyui/machine">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>服务器管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || comfyUISceneShow" index="/comfyui/task">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>任务管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || comfyUISceneShow" index="/comfyui/tool">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>工作流管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || comfyUISceneShow" index="/comfyui/lora">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>LORA管理</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu v-if="isSuperAdmin || bigModelShow" index="big-model">
          <template #title>
            <cloth-icon class="el-icon"></cloth-icon>
            <span>大模型管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || bigModelShow" index="/big-model/api-config">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>API管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || bigModelShow" index="/big-model/role-config">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>模型角色</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || bigModelShow" index="/big-model/chat-record">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>模型对话</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu v-if="isSuperAdmin || systemManageShow" index="system-manage">
          <template #title>
            <cloth-icon class="el-icon"></cloth-icon>
            <span>系统管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <el-link style="color: white" href="https://taitan.iaimarket.com/snail-job/" target="_blank">调度系统</el-link>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || systemManageShow" index="/system-manage/feedback">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>用户反馈</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || systemManageShow" index="/system-manage/config">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>系统参数</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="flex flex-col justify-center" style="background-color: rgb(235.9, 245.3, 255)">
        <el-page-header :icon="null">
          <template #title>
            <el-icon v-if="!isCollapsed" @click="isCollapsed = !isCollapsed">
              <Expand/>
            </el-icon>
            <el-icon v-else @click="isCollapsed = !isCollapsed">
              <Fold/>
            </el-icon>
          </template>
          <template #content>
            <div class="flex items-center">
              <div>
                <img src="@/assets/logo/logo.svg" alt="Logo"/>
              </div>
            </div>
          </template>
          <template #extra>
            <div class="flex items-center">
              <el-avatar v-if="isLogin"
                         :size="36"
                         class="mr-3"
                         :src="require('@/assets/default-avatar.png')"
              />
              <span v-if="isLogin" class="text-large font-600 mr-3"> {{ userInfo.username }} </span>
              <div v-if="isLogin" class="coin-charge">
                <gold-coin-icon></gold-coin-icon>
                <div class="amount">{{ coin }}</div>
                <div class="button" @click="handleClickPay">充值</div>
              </div>
              <div style="margin-left: 20px">
                <el-button v-if="isLogin" link type="info" @click="handleLoginOut">退出登录</el-button>
                <el-button v-else link type="success" @click="handleClickLogin">注册/登录</el-button>
              </div>
            </div>
          </template>
        </el-page-header>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>

  <!-- 登录弹出框，通过 v-if 控制是否显示 -->
  <div v-if="showLoginOverlay" class="login-overlay">
    <div class="login-popup">
      <button class="close-button" @click="closeLoginPopup">×</button> <!-- 右上角的关闭按钮 -->
      <Login @login-success="closeLoginPopup"/>  <!-- 使用自定义的 close 事件关闭弹窗 -->
    </div>
  </div>

  <el-dialog
      v-model="showPayDialog"
      width="500">
    <!-- 二维码 -->
    <div class="qrcode-container mt-8">
      <img src="@/assets/images/qrcode.png" alt="Contact Us" class="mx-auto"/>
      <p class="mt-2">欢迎联系我们🎉🎉</p>
    </div>
  </el-dialog>
</template>

<script>
import {
  Expand, Fold,
  Document, Menu as IconMenu, Location, Setting, User, Promotion, HelpFilled, Message, Histogram
} from '@element-plus/icons-vue';

import DouyinIcon from '@/components/icons/DouyinIcon.vue';
import LumaIcon from "@/components/icons/LumaIcon.vue";
import ClothIcon from '@/components/icons/ClothIcon.vue';
import GoldCoinIcon from "@/components/icons/GoldCoinIcon.vue";
import axiosInstance from '@/api/axiosInstance';
import Login from "@/views/Login.vue";


export default {
  components: {
    DouyinIcon, LumaIcon, ClothIcon,
    Message, Document, IconMenu, Location, Setting,
    User, Promotion, HelpFilled, Histogram,
    GoldCoinIcon, Expand, Fold, Login
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    routePaths() {
      return this.$store.state.routePaths;
    },
    coin() {
      return this.$store.state.coin || 0;
    },
  },
  mounted() {
    this.updateLoginStatus();
    console.log('isLogin:', this.isLogin);
    console.log('isSuperAdmin:', this.isSuperAdmin);
    console.log('Current Tenant ID:', this.tenantId);
    console.log('UserInfo:', this.userInfo)
    console.log('routePaths:', this.routePaths);
    this.updateMenuVisibility(this.routePaths);
    this.queryCoin();
  },
  data() {
    return {
      isCollapsed: true, // 是否折叠
      dyAccountManagerShow: false,
      dyAccountWorkShow: false,
      dyAccountWorkDataShow: false,
      lumaAccountShow: false,
      lumaWorkShow: false,
      contentMaterialShow: false,
      contentProductShow: false,

      aiTryOnMenuShow: false, // AI换装菜单
      aiTryOnDemoShow: false, // AI换装Demo
      videoMixMenuShow: false, // 视频混剪菜单
      videoMixSceneShow: false, // 视频混剪场景
      comfyUISceneShow: false, // ComfyUI场景
      bigModelShow: false, // 大模型模块
      systemManageShow: false, // 系统管理模块
      activeIndex: this.$route.path,
      pageTitle: this.$route.meta.title || 'Home',
      showLoginOverlay: false,
      showPayDialog: false,
    };
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path;
    },
  },
  methods: {
    updateLoginStatus() {
      if (localStorage.getItem('loginData')) {
        this.$store.dispatch('login', JSON.parse(localStorage.getItem('loginData')));
      } else {
        this.$store.dispatch('logout');
      }
    },
    queryCoin() {
      console.log('queryCoin:', this.userInfo)
      if (this.userInfo && this.userInfo.userId) {
        axiosInstance.get('/api/user/coin').then(response => {
          if (response.data && response.data.code === 0) {
            if (response.data.data) {
              this.$store.dispatch('resetCoin', response.data.data.coin);
            }
          }
        }).catch(error => {
          console.error(error)
        });
      }
    },
    updateMenuVisibility(routePaths) {
      if (routePaths) {
        const paths = routePaths.split(',');
        this.dyAccountManagerShow = paths.includes('/douyin/account');
        this.dyAccountWorkShow = paths.includes('/douyin/work');
        this.dyAccountWorkDataShow = paths.includes('/douyin/work-data');
        this.lumaAccountShow = paths.includes('/video-create/account');
        this.lumaWorkShow = paths.includes('/video-create/work');
        this.contentMaterialShow = paths.includes('/content/material');
        this.contentProductShow = paths.includes('/content/work');
        this.videoMixSceneShow = paths.includes('/video-mix/scene');
        this.comfyUISceneShow = paths.includes('/comfyui/**');
        this.bigModelShow = paths.includes('/big-model/**');
      } else {
        this.dyAccountManagerShow = false;
        this.dyAccountWorkShow = false;
        this.dyAccountWorkDataShow = false;
        this.lumaAccountShow = false;
        this.lumaWorkShow = false;
        this.contentMaterialShow = false;
        this.contentProductShow = false;
        this.videoMixSceneShow = false;
        this.comfyUISceneShow = false;
        this.bigModelShow = false;
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClickLogin() {
      this.showLoginOverlay = true;  // 显示登录弹窗
    },
    closeLoginPopup() {
      this.showLoginOverlay = false;  // 关闭登录弹窗
    },
    handleLoginOut() {
      this.$store.dispatch('logout');
      localStorage.clear();
      this.$message.warning("已退出登录");
      // this.$router.push('/login');
    },
    handleClickPay() {
      this.showPayDialog = true;
    },
  },
};
</script>

<style scoped>
.golden-text {
  color: rgb(255, 193, 37);
  font-weight: bold;
}

.menu-demo {
  height: 100%;
}

/* 全屏覆盖层 */
.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 确保覆盖在所有内容上 */
}

/* 弹窗样式 */
.login-popup {
  position: relative; /* 为了让关闭按钮正确定位 */
  background-color: rgba(255, 255, 255, 0.8); /* 弹窗背景透明 */
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 400px;
  z-index: 1001;
}

/* 右上角关闭按钮 */
.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: red; /* 鼠标悬停时颜色变化 */
}

.coin-charge {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 50px;
  background-color: #ffe8d6;
  font-family: Arial, sans-serif;
  height: 36px;
}

.coin-charge .icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.coin-charge .amount {
  font-size: 18px;
  color: #b3702a;
  margin-right: 12px;
}

.coin-charge .button {
  background-color: #c07329;
  color: white;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
  cursor: pointer;
  height: 25px;
  width: 40px;
}

.coin-charge .button:hover {
  background-color: #a86022;
}

.qrcode-container img {
  width: 150px;
  height: 150px;
}

.qrcode-container p {
  font-size: 16px; /* 增大字体 */
  margin-top: 20px;
  text-align: center; /* 让文字居中 */
}
</style>
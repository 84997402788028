<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="90px">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="queryParams.username" placeholder="用户名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="queryParams.remark" placeholder="备注，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getTableData">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="tableData" v-loading="tableLoading" height="calc(100vh - 250px)" style="width: 100%;">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="user_id" label="用户ID" width="150"></el-table-column>
      <el-table-column prop="username" label="用户名" width="150"></el-table-column>
      <el-table-column prop="description" label="问题描述" width="300"></el-table-column>
      <el-table-column prop="image_url" label="附图" width="300">
        <template #default="scope">
          <div v-if="scope.row.image_url"
               style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <el-image :src="scope.row.image_url"
                      fit="contain"
                      class="try-on-result-image"
                      :zoom-rate="1.2"
                      :max-scale="7"
                      :min-scale="0.5"
                      preview-teleported
                      :preview-src-list="[scope.row.image_url]">
            </el-image>
          </div>
          <div v-else style="text-align: center; color: #999999">
            无
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="contact" label="联系方式" width="150"></el-table-column>
      <el-table-column prop="remark" label="备注" width="150">
        <template #default="scope">
          <el-input v-model="scope.row.remark" @change="editRemark(scope.row)"
                    placeholder="输入备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="200"/>
      <el-table-column prop="update_time" label="更新时间" width="200"/>
      <el-table-column fixed="right" label="操作" width="200">
        <template #default="scope">
          <div style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap">
            <el-button style="margin-bottom: 4px;" type="danger" size="small"
                       @click.prevent="handleClickDelete(scope.row)">删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.page_num"
                v-model:limit="queryParams.page_size" @pagination="getTableData"/>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Check, ArrowDown, Upload} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";

export default {
  name: "big-model-role-config",
  components: {
    Pagination,
    CirclePlusFilled, Search, Download, Check, ArrowDown, Upload
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },

  data() {
    return {
      userInfoList: [],
      selectedIds: [],
      queryParams: {
        username: "",
        page_num: 1,
        page_size: 10,
        remark: null,
      },
      tableLoading: false,
      tableData: [],
      total: 0,
    };
  },
  created() {
    if (this.$route.query.taskId) {
      this.queryParams.taskId = this.$route.query.taskId;
    }
    if (this.$route.query.uid) {
      this.queryParams.userId = this.$route.query.uid;
    }
  },
  mounted() {
    if (this.isSuperAdmin) {
      //this.queryUserInfo(null);
    }
    this.getTableData();
  },
  methods: {
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      this.selectedIds = rows.map(item => item.id) || [];
    },
    getTableData() {
      this.tableLoading = true;
      axiosInstance.post('/api/system/feedback/page_query', {
        ...this.queryParams
      }).then(response => {
        this.tableLoading = false;
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.total = data.total;
          this.tableData = data.results;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.tableLoading = false;
        console.error(error)
      });
    },
    // 编辑备注
    editRemark(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        remark: row.remark
      };
      axiosInstance.post('/api/system/feedback/edit_remark', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('备注更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('备注更新失败');
          });
    },
    handleClickDelete(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosInstance.post('/api/system/feedback/delete', {
          id: row.id
        }).then(response => {
          if (response.data && response.data.code === 0) {
            this.$message.success('删除成功');
            this.getTableData();
          } else {
            this.$message.error(response.data.msg);
          }
        }).catch(error => {
          console.error(error)
          this.$message.error('删除失败');
        });
      })
    },
  },
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

::v-deep .el-table th {
  text-align: center; /* 设置所有表头单元格的文本居中 */
}

.try-on-result-image {
  flex-shrink: 0;
  cursor: pointer;
  border: 1px solid transparent; /* 默认透明的边框 //border-radius: 20px; */
  width: 240px;
  height: 320px;
  margin: 10px;
  z-index: 1;
}
</style>
<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="90px">
      <el-form-item label="角色码" prop="role_code">
        <el-input v-model="queryParams.role_code" placeholder="角色码，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="角色名" prop="role_name">
        <el-input v-model="queryParams.role_name" placeholder="角色名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="queryParams.remark" placeholder="备注，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getTableData">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="handleClickAdd">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          <span>新增</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="tableData" v-loading="tableLoading" height="calc(100vh - 250px)" style="width: 100%;">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="role_code" label="角色码" width="150"></el-table-column>
      <el-table-column prop="role_name" label="角色名" width="150"></el-table-column>
      <el-table-column prop="system_role" label="系统角色" width="250"></el-table-column>
      <el-table-column prop="user_role" label="用户角色" width="250"></el-table-column>
      <el-table-column prop="remark" label="备注" width="200">
        <template #default="scope">
          <el-input v-model="scope.row.remark" @change="editRemark(scope.row)"
                    placeholder="输入备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间"/>
      <el-table-column prop="update_time" label="更新时间"/>
      <el-table-column fixed="right" label="操作" width="250">
        <template #default="scope">
          <div style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap">
            <el-button style="margin-bottom: 4px;margin-left: 12px" type="primary" size="small"
                       @click.prevent="handleClickEdit(scope.row)">编辑
            </el-button>
            <el-button style="margin-bottom: 4px;" type="danger" size="small"
                       @click.prevent="handleClickDelete(scope.row)">删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.page_num"
                v-model:limit="queryParams.page_size" @pagination="getTableData"/>
    <!--编辑区域-->
    <div>
      <el-dialog title="大模型API配置"
                 v-model="dialogVisible"
                 width="60%"
                 :close-on-click-modal="false">
        <el-form
            :model="recordForm"
            label-position="left"
            label-width="auto">
          <el-form-item label="角色码：" prop="role_code" required>
            <el-input v-model="recordForm.role_code" :disabled="isUpdate" placeholder="请输入角色码">
            </el-input>
          </el-form-item>
          <el-form-item label="角色名：" prop="role_name" required>
            <el-input v-model="recordForm.role_name"
                      placeholder="请输入角色名">
            </el-input>
          </el-form-item>
          <el-form-item label="系统角色：" prop="system_role">
            <el-input v-model="recordForm.system_role" placeholder="请输入系统角色">
            </el-input>
          </el-form-item>
          <el-form-item label="用户角色：" prop="user_role">
            <el-input v-model="recordForm.user_role"
                      placeholder="请输入用户角色">
            </el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input v-model="recordForm.remark"
                      placeholder="请输入备注">
            </el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveEditInfo" :loading="submitLoading">保存</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Check, ArrowDown, Upload} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";

export default {
  name: "big-model-role-config",
  components: {
    Pagination,
    CirclePlusFilled, Search, Download, Check, ArrowDown, Upload
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },

  data() {
    return {
      userInfoList: [],
      selectedIds: [],
      recordForm: {
        id: null,
        role_code: '',
        role_name: '',
        system_role: '',
        user_role: '',
        remark: '',
      },
      queryParams: {
        page_size: 10,
        page_num: 1,
        role_code: null,
        role_name: null,
        remark: null,
      },
      tableLoading: false,
      tableData: [],
      total: 0,
      dialogVisible: false,
      isUpdate: false,
      submitLoading: false,
    };
  },
  created() {
    if (this.$route.query.taskId) {
      this.queryParams.taskId = this.$route.query.taskId;
    }
    if (this.$route.query.uid) {
      this.queryParams.userId = this.$route.query.uid;
    }
  },
  mounted() {
    if (this.isSuperAdmin) {
      this.queryUserInfo(null);
    }
    this.getTableData();
  },
  methods: {
    resetForm() {
      this.recordForm = {
        id: null,
        role_code: '',
        role_name: '',
        system_role: '',
        user_role: '',
        remark: '',
      }
    },
    queryUserInfo(username) {
      console.log("queryUserInfo: ", username)
      axiosInstance.post('/api/user/page_query', {
        "username": username || "",
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.userInfoList = response.data.data.results;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error);
      });
    },
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      this.selectedIds = rows.map(item => item.id) || [];
    },
    getTableData() {
      this.tableLoading = true;
      axiosInstance.post('/api/big_model/role_config/page_query', {
        ...this.queryParams
      }).then(response => {
        this.tableLoading = false;
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.total = data.total;
          this.tableData = data.results;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.tableLoading = false;
        console.error(error)
      });
    },
    handleClickAdd() {
      this.resetForm();
      this.isUpdate = false;
      this.dialogVisible = true;
    },
    // 编辑备注
    editRemark(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        remark: row.remark
      };
      axiosInstance.post('/api/big_model/role_config/edit_remark', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('备注更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('备注更新失败');
          });
    },
    // 查看参数
    handleClickEdit(row) {
      this.isUpdate = true;
      this.submitLoading = false;
      this.recordForm = row;
      this.dialogVisible = true;
    },
    // 编辑任务参数
    saveEditInfo() {
      if (!this.recordForm.role_code) {
        this.$message.error('请输入角色码');
        return;
      }
      if (!this.recordForm.role_name) {
        this.$message.error('请输入角色名');
        return;
      }
      this.submitLoading = true;
      axiosInstance.post('/api/big_model/role_config/update', {
        ...this.recordForm
      }).then(response => {
        this.submitLoading = false;
        if (response.data && response.data.code === 0) {
          this.$message.success('操作成功');
          this.dialogVisible = false;
          this.getTableData();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.submitLoading = false;
        console.error(error)
        this.$message.error(error.message);
      });
    },
    handleClickDelete(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosInstance.post('/api/big_model/role_config/delete', {
          id: row.id
        }).then(response => {
          if (response.data && response.data.code === 0) {
            this.$message.success('删除成功');
            this.getTableData();
          } else {
            this.$message.error(response.data.msg);
          }
        }).catch(error => {
          console.error(error)
          this.$message.error('删除失败');
        });
      })
    },
  },
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.try-on-result-image {
  flex-shrink: 0;
  cursor: pointer;
  border: 1px solid transparent; /* 默认透明的边框 //border-radius: 20px; */
  width: 270px;
  height: 360px;
  margin: 10px;
  z-index: 1;
}
</style>